


































































import { crudGet } from "@/api/_request";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";
import CustomButton from "./Button.vue";
import debounce from "debounce";

interface Option {
	name: string;
	value: any;
}

@Component({
	components: {
		"v-select": vSelect,
		BSpinner,
		CustomButton,
	},
})
export default class CustomSelect extends Vue {
	@Prop({ default: "" }) selectLabel!: string;
	@Prop({ default: "Selecione" }) placeholder!: string;
	@Prop({ default: "" }) error!: string;
	@Prop({ default: "" }) classStyle: string;
	@Prop({ default: "" }) value!: any;
	@Prop({ default: false }) isReadOnly!: boolean;
	@Prop({ default: false }) isVariable!: boolean;
	@Prop({ default: "row" }) axis!: "row" | "col";
	@Prop({ default: "Nenhuma opção disponível" }) emptyListMessage!: string;
	@Prop({ default: false }) shouldAddPushTagsClass: boolean;
	@Prop({ default: "96px" }) labelWidth!: number;
	@Prop({ default: false }) shouldDisplayAnyway!: boolean;
	@Prop({ default: false }) isLoading!: boolean;
	@Prop({ default: () => [] }) options!: Option[];
	@Prop({ default: false }) required!: boolean;
	@Prop({ default: false }) shouldUseCustomSearch!: boolean;
	@Prop({ default: false }) manyToOne!: boolean;
	@Prop({ default: false }) oneToMany!: boolean;
	@Prop({ default: false }) multiple!: boolean;
	@Prop({ default: false }) taggable!: boolean;
	@Prop({ default: false }) modifyValue!: boolean;
	@Prop({ default: false }) shouldShowSelectAllButton!: boolean;
	@Prop({ default: false }) shouldShowAddButton!: boolean;
	@Prop({ default: () => null }) onAddButtonFunciton: Function;
	@Prop({ default: (value: string) => value }) createOption: (newOption: string) => any;
	@Prop({ default: () => null }) onSearch: Function;
	@Prop({ default: (event: Event) => null }) onChange!: (value: any) => void;
	@Prop({ default: () => null }) fetchOptions!: {
		endpoint: string;
		params: any;
		allOption: string;
		nameKey?: string;
		valueKey?: string;
		responseAccessKey?: string;
		transformArray: (array: any[]) => any[];
	};

	debouncedLocalSearch = debounce(this.localSearch, 300);

	fetchedOptions: Option[] = [];
	localOptions: any[] = [];

	async localSearch(value: any) {
		if (this.shouldUseCustomSearch) {
			await this.customSearch(value);
		} else {
			this.onSearch?.(value);
		}
	}

	async customSearch(value: any) {
		if (this.fetchOptions) {
			const { endpoint, nameKey, valueKey, params, allOption, responseAccessKey, transformArray } = this.fetchOptions;
			Object.assign(params, { searchParam: value });
			const response = await crudGet(endpoint, "", params);

			let array = responseAccessKey ? response[responseAccessKey] : response ?? [];
			if (transformArray) {
				array = transformArray(array);
			}
			const options = !(nameKey || valueKey)
				? array
				: array.data
				? array.data.map((option: any) => ({ name: option[nameKey!], value: option[valueKey!] }))
				: array.map((option: any) => ({ name: option[nameKey!], value: option[valueKey!] }));

			this.fetchedOptions = [...(allOption ? [{ name: allOption, value: null }] : []), ...options];
		}
	}

	@Watch("value")
	onValueChange() {
		this.onChange?.(this.value);
	}

	@Watch("options")
	checkOption() {
		if (typeof this.value === "string" || !this.onSearch) {
			this.localOptions = this.options;
		} else if (this.onSearch) {
			const tempValue =
				this.value && this.value.length
					? this.value?.reduce((values: any[], currentValue: any) => {
							const mainKey = Object.keys(currentValue)[0];
							//TODO: Deixar essa parte mais generica
							const newOption = {
								name: currentValue[mainKey].productName
									? `${currentValue[mainKey].productName} - ${currentValue[mainKey].substanceName}`
									: currentValue[mainKey].name,
								value: currentValue,
							};
							if (!this.options.filter(option => option.name === newOption.name).length) {
								values.push(newOption);
							}
							return values;
					  }, [])
					: [];
			this.localOptions = tempValue ? [...this.options, ...tempValue] : this.options;
		}

		if (typeof this.value === "string") {
			const valueInOptions = this.localOptions?.find(option => option?.value === this.value);
			if (!valueInOptions && !this.multiple && this.modifyValue) {
				this.$emit("input", "");
			}
		} else if (this.value && Object.keys(this.value).length) {
			let valueInOptions: any = "";
			if (Array.isArray(this.value)) {
				valueInOptions = this.value.filter((currentValue: any) => {
					return this.localOptions?.find(option => {
						if (currentValue.insurerPlan && !currentValue.insurerPlan.description) {
							currentValue.insurerPlan.description = null;
						}
						return JSON.stringify(currentValue) === JSON.stringify(option?.value);
					});
				});
			} else {
				valueInOptions = Object.keys(this.value).filter((currentValue: any) => {
					return this.localOptions?.find(option => JSON.stringify(currentValue) === JSON.stringify(option?.value));
				});
			}

			if (this.modifyValue) {
				this.$emit("input", valueInOptions);
			}
		}
	}

	async created() {
		if (this.fetchOptions) {
			const { endpoint, nameKey, valueKey, params, allOption, responseAccessKey, transformArray } = this.fetchOptions;
			const response = await crudGet(endpoint, "", params);
			let array = responseAccessKey ? response[responseAccessKey] : response ?? [];

			if (transformArray) {
				array = transformArray(array);
			}
			const options = !(nameKey || valueKey)
				? array
				: array.data
				? array.data.map((option: any) => ({ name: option[nameKey!], value: option[valueKey!] }))
				: array.map((option: any) => ({ name: option[nameKey!], value: option[valueKey!] }));

			this.fetchedOptions = [...(allOption ? [{ name: allOption, value: null }] : []), ...options];
		}
	}

	mounted() {
		this.checkOption();
	}

	selectAll() {
		this.$emit(
			"input",
			this.options?.map(option => option?.value),
		);
	}
}
